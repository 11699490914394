import React from 'react';
import axios from 'axios';
  
class PunchItem extends React.Component {
    constructor() {
    super();
    this.state = {
      input: {},
      errors: {}
    };
     
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
     
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  
    this.setState({
      input
    });
  }
     
  handleSubmit(event) {
    event.preventDefault();
  
    if(this.validate()){
  
        const post = this.state.input;
  
        axios.post(`http://localhost:8080/LiftAndShift/punchitem`, { post })
        .then(res => {
          console.log('res');
          console.log(res);
          console.log(res.data);
  
          let input = {};
          input["title"] = "";
          input["body"] = "";
          this.setState({input:input});
  
          alert('Post created successfully.');
  
        })
   
    }
  }
  
  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
  
      if (!input["title"]) {
        isValid = false;
        errors["title"] = "Please enter your title.";
      }
  
      if (!input["body"]) {
        isValid = false;
        errors["body"] = "Please enter your body.";
      }
  
      this.setState({
        errors: errors
      });
  
      return isValid;
  }
     
  render() {
    return (
      <div>
        <h1>Punch Item Entry</h1>
        <form onSubmit={this.handleSubmit}>
  
          <div className="form-group">
            <label for="title">Title:</label>
            <input 
              type="text" 
              name="title" 
              value={this.state.input.title}
              onChange={this.handleChange}
              className="form-control" 
              placeholder="Enter title" 
              id="title" 
						/>
            <div className="text-danger">{this.state.errors.title}</div>
            <label for="body">Body:</label>
            <textarea 
              name="body"
              value={this.state.input.body} 
              onChange={this.handleChange}
              placeholder="Enter body"
              className="form-control" 
						/>
            <div className="text-danger">{this.state.errors.body}</div>
          </div>
              
          <input type="button" value="Submit" className="btn.btn-success" onClick="handleSubmit" />
        </form>
      </div>
    );
  }
}
  
export default PunchItem;

//
//    <Card>
//      <Logo src="/lock-img.png" />
//			<h1>Sign In</h1>
//      <Form>
//        <Input
//          type="username"
//          value={email}
//          onChange={e => {
//            setEmail(e.target.value);
//          }}
//          placeholder="email"
//					className="form-control"
//        />
//        <Input
//          type="password"
//          value={password}
//          onChange={e => {
//            setPassword(e.target.value);
//          }}
//          placeholder="password"
//        />
//        <Button onClick={login}>Sign In</Button>
//      </Form>
//      <Link to="/signup">Don't have an account?</Link>
//      <Link to="/forgot">Forget Password?</Link>
//        { isError &&<Error>The username or password provided were incorrect!</Error> }
//    </Card>
