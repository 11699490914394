import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactGlobe from 'react-globe';

const useStyles = makeStyles({
  root: {
    maxWidth: 600,
  },
  media: {
    height: 600,
  },
});

export default function RotatingGlobe() {
  const classes = useStyles();

  return (
<>
					<ReactGlobe />
</>
  );
}
