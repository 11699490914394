/*
 * this works pretty well 2021-02-20 R2M
 * but I want to add in conditional display of an alert
*/
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class HelloCardForm extends React.Component {
	constructor(props) {
		super(props)
		console.log("ctor")
		console.log(props)
		this.state = {
			fname: this.props.fname,
			lname: this.props.lname,
			username: this.props.fname + this.props.lname,
    	formIsValid: 'false',
		};
	}

	componentDidLoad() {
		console.log("did load")
	}

	componentWillUnload() {
		console.log("will unload")
	}

	componetDidUpdate() {
		console.log("updating")
	}

	static getDerivedStateFromProps() {
		console.log("derived state")
	}

	formSubmitHandler = (event) => {
    event.preventDefault();
		this.setState({username: this.state.fname + " " + this.state.lname});
		//alert("thanks for submitting");
    this.setState({formIsValid: 'true'});
  }

	formChangeHandler = (event) => {
		let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val});
	}

  render() {
	console.log("rendering....")
   return (
<div class="mdc-card">
  <div class="mdc-card__primary-action">
    <div class="mdc-card__media mdc-card__media--square">
      <div class="mdc-card__media-content">Title</div>
    </div>
  </div>
  <div class="mdc-card__actions">
    <div class="mdc-card__action-buttons">
      <button class="mdc-button mdc-card__action mdc-card__action--button">
        <div class="mdc-button__ripple"></div>
        <span class="mdc-button__label">Action 1</span>
      </button>
      <button class="mdc-button mdc-card__action mdc-card__action--button">
        <div class="mdc-button__ripple"></div>
        <span class="mdc-button__label">Action 2</span>
      </button>
    </div>
    <div class="mdc-card__action-icons">
      <button class="material-icons mdc-icon-button mdc-card__action mdc-card__action--icon" title="Share">share</button>
      <button class="material-icons mdc-icon-button mdc-card__action mdc-card__action--icon" title="More options">more_vert</button>
    </div>
  </div>
</div>
   ); 
  }
}

export default HelloCardForm;

//ReactDOM.render(<HelloCardForm name="RoyC" />, document.getElementById('root'));
