import React from 'react'
import "./Home.css";
//import Product from "./Product"
//import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
//import MainGrid from './MainGrid'
import MainFlex from './MainFlex'
import CookieConsent from "react-cookie-consent";

function Home() {
    return (
			<>
			<MainFlex />
			</>
    )
}

export default Home
