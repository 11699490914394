import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import { Card } from './AuthForm'
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();

  return (
	<Card raised='true'>
    <div style={{padding: '50px'}}>
      <Divider />
      <List component="nav" aria-label="part types">
				<Tooltip disableFocusListener disableTouchListener title="A catalog of Magnetron parts" placement="top-start" >
	        <ListItemLink href="/magnetrons">
	          <ListItemText primary="Magnetrons" />
	        </ListItemLink>
				</Tooltip>

				<Tooltip disableFocusListener disableTouchListener title="A catalog of Switch parts" >
	        <ListItemLink href="/switches">
	          <ListItemText primary="Switches" />
	        </ListItemLink>
				</Tooltip>

        <ListItemLink href="/capacitors">
          <ListItemText primary="Capacitors" />
        </ListItemLink>
        <ListItemLink href="/diodes">
          <ListItemText primary="Diodes" />
        </ListItemLink>
        <ListItemLink href="/fuses">
          <ListItemText primary="Fuses" />
        </ListItemLink>
        <ListItemLink href="/thermostats">
          <ListItemText primary="Thermostats" />
        </ListItemLink>
      </List>
      <Divider />
      <List component="nav" aria-label="pages">
        <ListItemLink href="/home">
          <ListItemText primary="Home" />
        </ListItemLink>
        <ListItemLink href="/privacy-policy">
          <ListItemText primary="Privacy Policy" />
        </ListItemLink>
        <ListItemLink href="/copyright" >
          <ListItemText primary="Copyright" />
        </ListItemLink>
        <ListItemLink href="/about-us" >
          <ListItemText primary="AboutUs" />
        </ListItemLink>
        <ListItemLink href="/contact-us">
          <ListItemText primary="ContactUs" />
        </ListItemLink>
      <Divider />
      </List>
    </div>
	</Card>
  );
}
