import React from 'react'
import "./Home.css";
import "./Thermostats.css";
import Product from "./Product"

function Thermostats() {
    return (
        <div className="home">
        <div className="home__row">
             <Product 
                id="GMP-GLC_L190"
								partType="Thermostat"
                title="GLC L190"
                price={10.50}
                image="/DSCN0738.JPG"
								desc="190 F - 1/4 Terminals"
            />
             <Product 
                id="GMP-GLC_L220"
								partType="Thermostat"
                title="GLC L220"
                price={10.50}
                image="/DSCN0738.JPG"
								desc="220 F - 1/4 Terminals"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_L260"
								partType="Thermostat"
                title="GLC L260"
                price={10.50}
                image="/DSCN0738.JPG"
								desc="260 F - 1/4 Terminals"
            />
             <Product 
                id="GMP-GLC_L280"
								partType="Thermostat"
                title="GLC L280"
                price={10.50}
                image="/DSCN0738.JPG"
								desc="280 F - 1/4 Terminals"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_L300"
								partType="Thermostat"
                title="GLC L300"
                price={10.50}
                image="/DSCN0738.JPG"
								desc="300 F - 1/4 Terminals"
            />
             <Product 
                id="GMP-GLC_L350"
								partType="Thermostat"
                title="GLC L350"
                price={10.50}
                image="/DSCN0739.JPG"
								desc="350 F - 3/16 Terminals"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_100_CIT"
								partType="Thermostat"
                title="GLC 100 CIT"
                price={10.50}
                image="/DSCN0737.JPG"
								desc="100 C - 3/16 Terminals"
            />
        </div>
    </div>
       
    )
}

export default Thermostats
