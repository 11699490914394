import React from 'react';
import axios from 'axios';
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import { withRouter, Router, Link, Redirect, useHistory } from "react-router-dom";
import history from "./history";


function FancyBorder(props) {
  return (
    <div className={'FancyBorder FancyBorder-' + props.color}>
      {props.children}
    </div>
  );
}

function WelcomeDialog() {
  return (
    <FancyBorder color="blue">
      <h1 className="Dialog-title">
        Welcome
      </h1>
      <p className="Dialog-message">
        Thank you for visiting our spacecraft!
      </p>
    </FancyBorder>
  );
}

export default WelcomeDialog
