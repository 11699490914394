import React from 'react'
import "./CheckoutProduct.css"
import { useStateValue } from './Stateprovider'
import CurrencyFormat from 'react-currency-format';

function CheckoutProduct({id, partType, title, image, price }) {
    const [{ basket }, dispatch] = useStateValue();

    const removeFromBasket = () => {
				console.log("id is " + id);
        dispatch({
            type: "REMOVE_FROM_BASKET",
            id: id,
        })
    }

    return (
        <div className="checkoutProduct">
            <img className="checkoutProduct__image" src={image} alt="" />   

            <div className="checkoutProduct__info">
                <p className="checkoutProduct__title">{title}</p>
		            <CurrencyFormat 
			            renderText={(value) =>(
			               <p>
			                  <strong>{value}</strong>
			               </p>
			            )}
			            decimalScale={2}
			            fixedDecimalScale={true}
			            value={price}
			            displayType={'text'}
			            thousandSeparator={true}
			            prefix={"$"}
								/>
                <button onClick={removeFromBasket}>Remove from Basket</button>
            </div>         
        </div>
    )
}

export default CheckoutProduct
