import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";

class Copyright extends Component {
  render() {
    return (
			<Card raised="true" minWidth="800">
      <div className="Copyright">
				<h2>Copyright:</h2>
				<br />
				<ul>
				<li>This site and all of its contents are Copyright Global Microwave Parts 2021</li>
				</ul>
				<br />
			</div>
			</Card>
    );
  }
}

export default Copyright;
