import React from 'react';
import { useStateValue } from "./Stateprovider";
import { Link, Redirect, useHistory } from "react-router-dom";
import "./Order.css"
import OrderProduct from './OrderProduct';
import OrderSubtotal from './OrderSubtotal';
import Subtotal from "./Subtotal";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";

const winston = require('winston');
const logger  = new winston.transports.Console();
//const files = new winston.transports.File({ filename: './combined.log' });
winston.add(logger);


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function Order() {
    const [{ basket }] = useStateValue();
		const orderNo = getRandomInt(5000);
  	const history = useHistory();

		console.log("Order UP");
		console.log(basket);
		winston.log(basket);

		const collectShipTo = (event) => {
	    event.preventDefault(); // this stops the refresh!!!
      history.push("/ship-to-address");
	  };

    return (
        <div className="checkout">

            {basket?.length === 0 ? (
                <div>
                    <h2> Your Order is Empty</h2>
                    <p>You have no items in youir basket. </p>
										<p>To buy one or more items return to Home and start over.</p>
										<br />
										<br />
                </div>
            ) : 
            	(
								<>
								<div className="basket_info">
                  <h2 className="checkout__title">Your Order: {orderNo} has been received!
	              	<b><OrderSubtotal /></b>
									</h2>
        					<Button onClick={collectShipTo}>Add Shipping Address</Button>
									<p><b>  Items in your Order are:</b></p>
                  {/* List out all of the Order Products */}
                  {basket.map(item => (
                      <OrderProduct 
                      id={item.id}
                      partType={item.partType}
                      title={item.title}
                      image={item.image}
                      price={item.price}
                      rating={item.rating}
                      /> 
                  ))
									}
                </div>
								</>
            )}
        </div>
    );
}

export default Order
