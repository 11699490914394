import React from 'react';
import { Link } from 'react-router-dom';
import "./Header.css";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useStateValue } from './Stateprovider';

function Header() {
    const [{ basket }] = useStateValue();
    console.log(basket)
    return (
        <div>
          <nav className="header">
            <Link to="/">
						<img className="header__logo" src={'/5-1-globe.png'} alt="Globe Logo" height="150px" />
            </Link>

              <Link to="/" className="header__link">
                 <div className="header__option">
                 <span className ="header__optionLine2"></span>
                 </div>
              </Link>

                
            <div className="header__nav">
              <Link to="/" className="header__link">
                 <div className="header__option">
                 <span className ="header__optionLine2"></span>
                 </div>
              </Link>
                <Link to="/home">
                  <div>
                    <button class="header__button" >Home</button>
                  </div>
                </Link>

                <Link to="/magnetrons">
                  <div>
                    <button class="header__button" >Magnetrons</button>
                  </div>
                </Link>
                
                <Link to="/switches">
                  <div>
                    <button class="header__button" >Switches</button>
                  </div>
                </Link>
                
                <Link to="/capacitors">
                  <div>
                    <button class="header__button" >Capacitors</button>
                  </div>
                </Link>
                
                <Link to="/diodes">
                  <div>
                    <button class="header__button" >Diodes</button>
                  </div>
                </Link>
                
                <Link to="/fuses">
                  <div>
                    <button class="header__button" >Fuses</button>
                  </div>
                </Link>

                <Link to="/thermostats">
                  <div>
                    <button class="header__button" >Tstats</button>
                  </div>
                </Link>

              <Link to="/checkout" className="header__link">
              	<div className="header__optionBasket">
                	<ShoppingCartIcon />
                  <span className="header__optionLine2 header__basketCount">{basket?.length}</span>
                </div>
              </Link>
            	<Link to="/">
              	<div className="header__link">
									<img className="" src={'/FedEx-200px.png'} alt="FedEx Logo" height="80px" top-margin="40px" bottom-margin="10" />
									<p>Free Shipping for Online Orders</p>
                </div>
            	</Link>

						</div>

                
          </nav>  
        </div>
    )
}

export default Header
