import React from 'react';
import './MainFlex.css';

function getRandInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

class MainGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			time: new Date().toLocaleString(),

			onScreenTiles:  [ "/homeTiles/tile-01.jpg", "/homeTiles/tile-02.jpg", "/homeTiles/tile-03.jpg", "/homeTiles/tile-04.jpg", "/homeTiles/tile-05.jpg", "/homeTiles/tile-06.jpg", "/homeTiles/tile-07.jpg", "/homeTiles/tile-08.jpg" ],

			offScreenTiles: [ "/homeTiles/ovens-open-sm.png", "/homeTiles/oven-repair-sm.png", "/homeTiles/kit-tile-sm.png", "/homeTiles/oven-01.jpg", "/homeTiles/oven-02.jpg", "/homeTiles/oven-03.jpg", "/homeTiles/oven-04.jpg", "/homeTiles/oven-05.jpg" ],

		}
	}

swapTile() {
	let idxA = getRandInt(8);
	let idxB = getRandInt(8);
	//get a random GroupA Tile and a randow GroupB Tile
	let onScreen = this.state.onScreenTiles[idxA];
	let offScreen = this.state.offScreenTiles[idxB];
	//now swap the values
	this.state.onScreenTiles[idxA] = offScreen;
	this.state.offScreenTiles[idxB] = onScreen;
}

section = {
  height: "100%",
  paddingTop: 5,
	background: "#0033cc",
}

componentDidMount() {
  this.interval = setInterval(() => this.tick(), 2000);
}

componentWillUnmount() {
  clearInterval(this.interval);
}

tick() {
	this.swapTile();
	this.setState({
		time: new Date().toLocaleString(),
		tile_ptr_01: this.state.onScreenTiles[ 0 ],
		tile_ptr_02: this.state.onScreenTiles[ 1 ],
		tile_ptr_03: this.state.onScreenTiles[ 2 ],
		tile_ptr_04: this.state.onScreenTiles[ 3 ],
		tile_ptr_05: this.state.onScreenTiles[ 4 ],
		tile_ptr_06: this.state.onScreenTiles[ 5 ],
		tile_ptr_07: this.state.onScreenTiles[ 6 ],
		tile_ptr_08: this.state.onScreenTiles[ 7 ],
	});
}

render () {
    return (

			<ul style={{background:"#0033cc"}}>
				<ui>
					<img src={this.state.tile_ptr_01} onerror="/homeTiles/tile-01.jpg" />
				</ui>
				<ui>
					<img src={this.state.tile_ptr_02} onerror="/homeTiles/tile-01.jpg" />
				</ui>
				<ui>
					<img src={this.state.tile_ptr_03} onerror="/homeTiles/tile-01.jpg" />
				</ui>
				<ui>
					<img src={this.state.tile_ptr_04} onerror="/homeTiles/tile-01.jpg" />
				</ui>
				<ui>
					<img src={this.state.tile_ptr_05} onerror="/homeTiles/tile-01.jpg" />
				</ui>
				<ui>
					<img src={this.state.tile_ptr_06} onerror="/homeTiles/tile-01.jpg" />
				</ui>
				<ui>
					<img src={this.state.tile_ptr_07} onerror="/homeTiles/tile-01.jpg" />
				</ui>
				<ui>
					<img src={this.state.tile_ptr_08} onerror="/homeTiles/tile-01.jpg" />
				</ui>
			</ul>
    )
	}
}

export default MainGrid

