import React from 'react';
import { Link } from 'react-router-dom';
import "./Header.css";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { useStateValue } from './Stateprovider';
//import { Container, Row, Col } from 'react-grid-system';
import { Grid, Container } from '@material-ui/core';
import { Typography } from '@material-ui/core';
//import randomColor from 'randomcolor';
import './HeaderGrid.css';
import PrintIcon from '@material-ui/icons/Print';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';

function HeaderGrid() {
    const [{ basket }] = useStateValue();

const section = {
  height: "40%",
  paddingTop: 5,
	background: "#0033cc",
};

    return (

 <div  className="App">
      <Grid container spacing={0} justify="left" >
        <Grid item xs={4} >
          <div style={{background:"#0033cc", color:"white", fontSize:30, height:"100%"}}><br /><h3>&nbsp;Global Microwave Parts<br /></h3><p style={{color:"#fcf803", fontSize:16, fontStyle:"italic" }}>&nbsp;&nbsp;For Retail Customers, Call to setup a Wholesale Account</p></div>
        </Grid>
        <Grid item xs={2}>
        <div>
          <nav className="header">
              	<div className="header__optionInfo">
									<table>
									<tr>
                	<td>
										<PhoneInTalkIcon />
										&nbsp;800-325-8488
                	</td>
									</tr>
									<tr>
                	<td>
                	<PrintIcon />
									&nbsp;800-433-2060
                	</td>
									</tr>
									</table>
                </div>
					</nav>
        </div>
        </Grid>
        <Grid item xs={6}>
        <div>
          <nav className="header">
              	<div className="header__optionInfo">
									<table>
									<tr>
                	<td>
										<LocationOnIcon />
										&nbsp;9894 Monroe Dr Dallas, TX 75220
                	</td>
									</tr>
									<tr>
                	<td>
                	<MailOutlineIcon />
									&nbsp;order@globalmicrowaveparts.com
                	</td>
									</tr>
									</table>
                </div>
					</nav>
        </div>
        </Grid>
      </Grid>
    </div>

    )
}

export default HeaderGrid

