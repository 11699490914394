import React from 'react';
import axios from 'axios';
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import { withRouter, Router, Link, Redirect, useHistory } from "react-router-dom";
import history from "./history";
import globalUserStateObject from './globalUserStateObject';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
  
class ShipToAddress extends React.Component {
    constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
    };
     
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
     
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  
    this.setState({
      input
    });
  }
     
  handleSubmit(event) {
    event.preventDefault();
  
    if(this.validate()){
  
        const post = this.state.input;
  
        axios.post(`http://cyberpojos.com:8080/LiftAndShift/post`, { post })
        .then(res => {
          console.log('res');
          console.log(res);
          console.log(res.data);
  
          let input = {};
          input["addressLine1"] = "";
          input["addressLine2"] = "";
          input["city"] = "";
          input["geoState"] = this.state.input.geoState;
          input["postalCode"] = "";

					//we set the state to mostly cleaned out
          this.setState({input:input});
					//let globalUserStateObject= {geoState: input["geoState"]};
					globalUserStateObject.geoState = input["geoState"];
  
					this.thanks();
        })
   
    }
  }
  
  thanks() {
    alert('Thanks your Ship To Address has been accepted.');
    this.props.history.push({
			pathname: '/order-confirmation',
			geoState: "TX",
			//geoState: this.state.input.geoState,
		})
	}
     
  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
  
      if (!input["addressLine1"]) {
        isValid = false;
        errors["addressLine1"] = "Please enter your street address.";
      }
  
      if (!input["city"]) {
        isValid = false;
        errors["city"] = "Please enter your city.";
      }
  
      if (!input["geoState"]) {
        isValid = false;
        errors["geoState"] = "Please enter your State.";
      }
  
      if (!input["postalCode"]) {
        isValid = false;
        errors["postalCode"] = "Please enter your zip code.";
      }
  
      this.setState({
        errors: errors
      });
  
      return isValid;
  }

  render() {
    return (
			<Card >
			<LocalShippingIcon fontSize="large" />
			<h1>Ship To Address</h1>
			<br />
      <div>
        <Form >
  
          <div className="form-group">
            <input 
              type="text" 
              name="addressLine1" 
              value={this.state.input.addressLine1}
              onChange={this.handleChange}
              className="form-control" 
              placeholder="addressLine1" 
              id="shipTo" />
  
              <div className="text-danger">{this.state.errors.addressLine1}</div>
          </div>
					<br />
          <div className="form-group">
            <input 
              type="text" 
              name="addressLine2" 
              value={this.state.input.addressLine2}
              onChange={this.handleChange}
              className="form-control" 
              placeholder="addressLine2" 
              id="shipTo" />
  
              <div className="text-danger">{this.state.errors.addressLine2}</div>
          </div>
					<br />
  
          <div className="form-group">
            <input
              name="city"
              value={this.state.input.city} 
              onChange={this.handleChange}
              className="form-control"
              placeholder="city" />
  
              <div className="text-danger">{this.state.errors.city}</div>
          </div>
					<br />
              
          <div className="form-group">
            <input
              name="geoState"
              value={this.state.input.geoState} 
              onChange={this.handleChange}
              className="form-control"
              placeholder="State" />
  
              <div className="text-danger">{this.state.errors.geoState}</div>
          </div>
					<br />
              
          <div className="form-group">
            <input
              name="postalCode"
              value={this.state.input.postalCode} 
              onChange={this.handleChange}
              className="form-control"
              placeholder="postalCode" />
  
              <div className="text-danger">{this.state.errors.postalCode}</div>
          </div>
					<br />
          
          <Button type="button" onClick={this.handleSubmit}>Submit your Shipping Info</Button>
					<br />
        </Form>
				<br />
      </div>
			</Card>
    );
  }
}
  
export default withRouter(ShipToAddress);
