import React from 'react';
import 'react-app-polyfill/stable';
import {
  BrowserRouter as Router,
  Switch,
  Route,
	Redirect,
} from "react-router-dom";
import './App.css';
import HelloForm from './HelloForm';
import HelloCardForm from './HelloCardForm';
import MinimalUI from './MinimalUI';
//import Header from './Header';
import HeaderGrid from './HeaderGrid';
//import HeaderNoNav from './HeaderNoNav';
import HeaderFullNav from './HeaderFullNav';
import HeaderWelcomeNav from './HeaderWelcomeNav';
import HeaderCatalogNav from './HeaderCatalogNav';
import Footer from './Footer';
import Subtotal from './Subtotal';
import Home from './Home';
import HomeMedia from './HomeMedia';
import HomeRotatingGlobe from './HomeRotatingGlobe';
import ExampleRotatingGlobe from './ExampleRotatingGlobe';
import SignIn from './SignIn';
import SignUp from './SignUp';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Checkout from './Checkout';
import ProductUpload from './ProductUpload';
import Site from './Site';
import SiteMap from './SiteMap';
import SiteHealth from './SiteHealth';
import Catalog from './Catalog';
import Magnetrons from './Magnetrons';
import Switches from './Switches';
import Fuses from './Fuses';
import Diodes from './Diodes';
import Capacitors from './Capacitors';
import Thermostats from './Thermostats';
import Order from './Order';
import Orders from './Orders';
import OrderConfirmation from './OrderConfirmation';
import Profile from './Profile';
import CreditCard from './CreditCard';
import Address from './Address';
import ShipToAddress from './ShipToAddress';
import PrivacyPolicy from './PrivacyPolicy';
import Copyright from './Copyright';
//import Contact from './Contact';
import PunchItem from './PunchItem';
import ForgotPassword from './ForgotPassword';
import { ScreenClassProvider } from 'react-grid-system';
import CreatePost from './CreatePost';
//import AuthenticatedRoute from './AuthenticatedRoute';
//import UnauthenticatedRoute from './UnauthenticatedRoute';
import PartsSearch from './PartsSearch';
import SimpleList from './SimpleList';
import WelcomeDialog from './WelcomeDialog'

const Console = prop => (
  console[Object.keys(prop)[0]](...Object.values(prop))
  ,null // ➜ React components must return something 
)

function App() {
  return (
<ScreenClassProvider>
    <Router >
    <div className="app">
      <Switch>

				<Route path='/s3' component={() => { 
				     window.location.href = 'http://react-testme.s3-website-us-west-2.amazonaws.com'; 
				     return null;
				}}/>
				<Route path='/ec2' component={() => { 
				     window.location.href = 'http://globalmicrowaveparts.com/';
				     return null;
				}}/>

        <Route path="/hello-card-form">
					<Console log="path is /hello-card-form" />
        	<HelloCardForm fname="Roy" lname="Cook"/>
        </Route>

        <Route path="/hello-form">
					<Console log="path is /hello-form" />
        	<HelloForm fname="Roy" lname="Cook"/>
        </Route>

        <Route path="/minimal-ui">
					<Console log="path is /minimal-ui" />
        	<MinimalUI fname="Roy" lname="Cook"/>
        </Route>

        <Route path="/site-health">
					<Console log="path is /site-health" />
        	<HeaderGrid />
        	<SiteHealth />
        </Route>
        
        <Route path="/product-upload">
					<Console log="path is /product-upload" />
        	<HeaderGrid />
        	<ProductUpload />
        </Route>
        
        <Route path="/magnetrons">
					<Console log="path is /magnetrons" />
        	<HeaderFullNav />
        	<Magnetrons />
        </Route>
        
        <Route path="/switches">
					<Console log="path is /switches" />
        	<HeaderFullNav />
        	<Switches />
        </Route>
        
        <Route path="/capacitors">
					<Console log="path is /capacitors" />
        	<HeaderFullNav />
        	<Capacitors />
        </Route>
        
        <Route path="/diodes">
					<Console log="path is /diodes" />
        	<HeaderFullNav />
        	<Diodes />
        </Route>
        
        <Route path="/fuses">
					<Console log="path is /fuses" />
        	<HeaderFullNav />
        	<Fuses />
        </Route>
        
        <Route path="/thermostats">
					<Console log="path is /thermostats" />
        	<HeaderFullNav />
        	<Thermostats />
        </Route>
        
        <Route path="/catalog">
					<Console log="path is /catalog" />
        	<HeaderFullNav />
        	<Catalog />
        </Route>
        
        <Route path="/order">
					<Console log="path is /order" />
        	<HeaderGrid />
        	<HeaderFullNav />
        	<Order />
        </Route>
        
        <Route path="/order-confirmation">
					<Console log="path is /order-confirmation" />
        	<HeaderGrid />
        	<HeaderFullNav />
        	<OrderConfirmation />
        </Route>
        
        <Route path="/checkout">
					<Console log="path is /checkout" />
        	<HeaderGrid />
        	<HeaderFullNav />
        	<Subtotal />
        	<Checkout />
        </Route>
        
        <Route path="/signup">
					<Console log="path is /signup" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<SignUp />
        </Route>
        
        <Route path="/signin">
        	<HeaderGrid />
        	<HeaderFullNav />
					<Console log="path is /signin" />
					<SignIn />
        </Route>
        
        <Route path="/signout">
					<Console log="path is /signout" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<Home />
        </Route>
        
        <Route path="/forgot">
        	<HeaderGrid />
        	<HeaderFullNav />
					<Console log="path is /forgot" />
					<ForgotPassword />
        </Route>
        
        <Route path="/contact-us">
					<Console log="path is /contact-us" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<ContactUs />
        </Route>
        
        <Route path="/about-us">
					<Console log="path is /about-us" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<AboutUs />
        </Route>

        <Route path="/site-map">
					<Console log="path is /site-map" />
        	<HeaderGrid />
        	<HeaderFullNav />
        	<Site />
        </Route>

        <Route path="/simple-list">
					<Console log="path is /simple-list" />
        	<HeaderGrid />
        	<HeaderFullNav />
        	<SimpleList />
        </Route>

        <Route path="/copyright">
					<Console log="path is /copyright" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<Copyright />
        </Route>

        <Route path="/privacy-policy">
					<Console log="path is /privacy-policy" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<PrivacyPolicy />
        </Route>

        <Route path="/profile">
					<Console log="path is /profile" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<Profile />
        </Route>

        <Route path="/bill-to">
					<Console log="path is /bill-to" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<Address />
        </Route>

        <Route path="/ship-to">
					<Console log="path is /ship-to" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<Address />
        </Route>

        <Route path="/ship-to-address">
					<Console log="path is /ship-to-address" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<ShipToAddress />
        </Route>

        <Route path="/credit-card">
					<Console log="path is /credit-card" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<CreditCard />
        </Route>

        <Route path="/punchitem">
					<Console log="path is /punchitem" />
        	<HeaderGrid />
        	<HeaderFullNav />
					<PunchItem />
        </Route>

        <Route path="/home">
					<Console log="path is /home" />
					<HeaderGrid />
					<HeaderFullNav />
          <Home />
        </Route>
        
        <Route path="/home-full">
					<Console log="path is /home-full" />
					<HeaderGrid />
					<HeaderFullNav />
          <HomeRotatingGlobe />
        </Route>
        
        <Route path="/home-media">
					<Console log="path is /home-media" />
					<HeaderGrid />
					<HeaderFullNav />
          <HomeRotatingGlobe />
        </Route>
        
        <Route path="/ex-rotating-globe">
					<Console log="path is /ex-rotating-globe" />
					<HeaderGrid />
					<HeaderFullNav />
          <ExampleRotatingGlobe />
        </Route>
        
        <Route path="/create-post">
					<Console log="path is /create-post" />
					<HeaderGrid />
					<HeaderFullNav />
          <CreatePost />
        </Route>
        
        <Route path="/orders">
					<Console log="path is /orders" />
        	<HeaderGrid />
        	<Orders />
        </Route>
        
        <Route path="/welcome-dialog">
					<Console log="path is /welcome-dialog" />
					<WelcomeDialog />
        </Route>
        
        <Route path="/parts-search">
					<Console log="path is /parts-search" />
					<HeaderGrid />
					<HeaderFullNav />
					<PartsSearch />
        </Route>
        
        <Route path="/">
					<Console log="path is /" />
					<HeaderGrid />
					<HeaderFullNav />
          <Home />
        </Route>
        
      </Switch>
			<Footer />
    </div>
    </Router>
</ScreenClassProvider>
  );
}

export default App;
