import React from 'react'
import "./Home.css";
import "./Catalog.css";
import Product from "./Product"

function Catalog() {
    return (
        <div className="home">
        <div className="home__row">
             <Product 
                id="GMP-15928"
								partType="Magnetron"
                title="15928"
                price={69.90}
                image="/15928.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M210"
								partType="Magnetron"
                title="2M210"
                price={69.90}
                image="/2M210.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M229E-EH"
								partType="Magnetron"
                title="2M229E-EH"
                price={69.90}
                image="/2M229E-EH.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M229E"
								partType="Magnetron"
                title="2M229E"
                price={69.90}
                image="/2M229E.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M229H3H"
								partType="Magnetron"
                title="2M229H3H"
                price={69.90}
                image="/2M229H3H.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M229H"
								partType="Magnetron"
                title="2M229H"
                price={69.90}
                image="/2M229H.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M240H"
								partType="Magnetron"
                title="2M240H"
                price={69.90}
                image="/2M240H.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M240H-NS"
								partType="Magnetron"
                title="2M240H-NS"
                price={69.90}
                image="/2M240H-NS.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M244"
								partType="Magnetron"
                title="2M244"
                price={69.90}
                image="/2M244.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M248E"
								partType="Magnetron"
                title="2M248E"
                price={69.90}
                image="/2M248E.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M248H-EH"
								partType="Magnetron"
                title="2M248H-EH"
                price={69.90}
                image="/2M248H-EH.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M248H"
								partType="Magnetron"
                title="2M248H"
                price={69.90}
                image="/2M248H.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M248H-NS"
								partType="Magnetron"
                title="2M248H-NS"
                price={69.90}
                image="/2M248H-NS.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M248J"
								partType="Magnetron"
                title="2M248J"
                price={69.90}
                image="/2M248J.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M248K"
								partType="Magnetron"
                title="2M248K"
                price={69.90}
                image="/2M248K.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M248K-NS"
								partType="Magnetron"
                title="2M248K-NS"
                price={69.90}
                image="/2M248K-NS.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M254H"
								partType="Magnetron"
                title="2M254H"
                price={69.90}
                image="/2M254H.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-2M254J"
								partType="Magnetron"
                title="2M254J"
                price={69.90}
                image="/2M254J.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M303H"
								partType="Magnetron"
                title="2M303H"
                price={69.90}
                image="/2M303H.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-4150019905"
								partType="Magnetron"
                title="4150019905"
                price={69.90}
                image="/4150019905.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-4358059"
								partType="Magnetron"
                title="4358059"
                price={69.90}
                image="/4358059.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-57-030"
								partType="Magnetron"
                title="57-030"
                price={69.90}
                image="/57-030.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-WB27X10249"
								partType="Magnetron"
                title="WB27X10249"
                price={69.90}
                image="/WB27X10249.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-WB27X10249-EH"
								partType="Magnetron"
                title="WB27X10249-EH"
                price={69.90}
                image="/WB27X10249-EH.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-WB27X10249-NS-BH"
								partType="Magnetron"
                title="WB27X10249-NS-BH"
                price={69.90}
                image="/WB27X10249-NS-BH.JPG"
								desc="A basic part with great qualities a good value."
            />
             <Product 
                id="GMP-WB27X10505"
								partType="Magnetron"
                title="WB27X10505"
                price={69.90}
                image="/WB27X10505.JPG"
								desc="A basic part with great qualities a good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_14-104_1/4"
								partType="Switch"
                title="GLC_14-104 1/4"
                price={10.95}
                image="/GLC_14-104_1:4.JPG"
								desc="A basic switch with good qualities and good value."
            />
             <Product 
                id="GMP-GLC_14-104_3/16"
								partType="Switch"
                title="GLC_14-104 3/16"
                price={10.95}
                image="/GLC_14-104_3:16.JPG"
								desc="A basic switch with good qualities and good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_14-22A"
								partType="Switch"
                title="GLC_14-22A"
                price={10.95}
                image="/GLC_14-22A.JPG"
								desc="A basic switch with good qualities and good value."
            />
             <Product 
                id="GMP-22A316ZE"
								partType="Switch"
                title="22A316ZE"
                price={10.95}
                image="/22A316ZE.JPG"
								desc="A basic switch with good qualities and good value."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_0526"
								partType="Diode"
                title="GLC_0526"
                price={10.25}
                image="/GLC_0526.JPG"
								desc="A solid diode with outstanding qualities."
            />
             <Product 
                id="GMP-GLC_0529"
								partType="Diode"
                title="GLC_0529"
                price={10.25}
                image="/GLC_0529.JPG"
								desc="A solid diode with outstanding qualities."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_0530"
								partType="Diode"
                title="GLC_0530"
                price={11.25}
                image="/GLC_0530.JPG"
								desc="A solid diode with outstanding qualities."
            />
             <Product 
                id="GMP-GLC_3-A"
								partType="Diode"
                title="GLC_3-A"
                price={10.25}
                image="/GLC_3-A.JPG"
								desc="A solid diode with outstanding qualities."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_3-M"
								partType="Diode"
                title="GLC_3-M"
                price={10.25}
                image="/GLC_3-M.JPG"
								desc="A solid diode with outstanding qualities."
            />
             <Product 
                id="GMP-WB27X1160"
								partType="Diode"
                title="WB27X1160"
                price={10.25}
                image="/WB27X1160.JPG"
								desc="A solid diode with outstanding qualities."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_HD30"
								partType="FuseHolder"
                title="GLC_HD30"
                price={10.25}
                image="/GLC_HD30.JPG"
								desc="Dependable fuse holder."
            />
             <Product 
                id="GMP-GLC_FNMH"
								partType="FuseHolder"
                title="GLC_FNMH"
                price={10.25}
                image="/GLC_FNMH.JPG"
								desc="Dependable fuse holder."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_FNM-20"
								partType="Fuse"
                title="GLC_FNM-20"
                price={10.25}
                image="/GLC_FNM-20.JPG"
								desc="Dependable fuse."
            />
             <Product 
                id="GMP-GLC_FNM-25"
								partType="Fuse"
                title="GLC_FNM-25"
                price={10.25}
                image="/GLC_FNM-25.JPG"
								desc="Dependable fuse."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_FNM-30"
								partType="Fuse"
                title="GLC_FNM-30"
                price={10.25}
                image="/GLC_FNM-30.JPG"
								desc="Dependable fuse."
            />
             <Product 
                id="GMP-GLC_4-20"
								partType="Fuse"
                title="GLC_4-20"
                price={10.25}
                image="/GLC_4-20.JPG"
								desc="Dependable fuse."
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-1.00_MFD"
								partType="Capacitor"
                title="1.00 MFD"
                price={22.90}
                image="/1.00_MFD.JPG"
								desc="We have most every kind of cpacitor."
            />
             <Product 
                id="GMP-0.97_MFD"
								partType="Capacitor"
                title="0.97 MFD"
                price={22.90}
                image="/0.97_MFD.JPG"
								desc="We have most every kind of cpacitor."
            />
        </div>
    </div>
       
    )
}

export default Catalog
