/*
 * This code is voodoo magic to me right now
 * Need to go understand this throughly
*/

// setup data layer 
//we need this to track the basket

import React, { createContext , useContext, useReducer } from "react";

//this is the Data layer
export const StateContext = createContext();

// build a provider
export const StateProvider = ({ reducer, initialState, children }) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);

// this is how we use it inside of component
export const useStateValue = () => useContext(StateContext);

