import React from 'react'
import "./Product.css"
import { useStateValue } from './Stateprovider'
import CurrencyFormat from 'react-currency-format';

function Product({id, partType, title, image, price, desc, rating}) {
    const [{ basket }, dispatch] = useStateValue();
    const addToBasket = () => {
        dispatch({
            type: 'ADD_TO_BASKET',
            item: {
                id: id,
                partType: partType,
                image: image,
                title: title,
                price: price,
                desc: desc,
            }
        })
    };
    return (
        <div className="product">
        <div className="product__info">

        	<p>PartType: {partType}</p>
        	<p>PartNumber: {title}</p>

            <CurrencyFormat 
	            renderText={(value) =>(
	               <p>
	                  Retail Price: <strong>{value}</strong>
	               </p>
	            )}
	            decimalScale={2}
	            fixedDecimalScale={2}
	            value={price}
	            displayType={'text'}
	            thousandSeparator={true}
	            prefix={"$"}
            />
        	<p>Desc: {desc}</p>

        </div>
           
            <img src={image} alt=""/>
            <button onClick={addToBasket}>Add to Basket</button>
        </div>
    )
}

export default Product
