import React from 'react'
import "./OrderProduct.css"
import { useStateValue } from './Stateprovider'
import CurrencyFormat from 'react-currency-format';

function OrderProduct({id, title, image, price, rating}) {
    const [{ basket }, dispatch] = useStateValue();

    return (
        <div className="orderProduct">
            <img className="checkoutProduct__image" src={image} alt="" />   

            <div className="checkoutProduct__info">
                <p className="checkoutProduct__title">{title}</p>
            <CurrencyFormat 
	            renderText={(value) =>(
	               <p>
	                  <strong>{value}</strong>
	               </p>
	            )}
	            decimalScale={2}
	            fixedDecimalScale={2}
	            value={price}
	            displayType={'text'}
	            thousandSeparator={true}
	            prefix={"$"}
            />
            </div>         
        </div>
    )
}

export default OrderProduct
