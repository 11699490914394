/*
 * This actually works!
 * So this is a good way to just put some simple HTML
 */

import React from 'react';

function MinimalUI () {
	return (
		<>
			Yo, this actually works!
		</>
	)
}
export default MinimalUI;
