import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import styled from 'styled-components'
import './PrivacyPolicy.css'

const StyledContainer = styled.div`
  max-width: 3550px;
  width: 100%;
  margin: auto;
`

class PrivacyPolicy extends Component {A

  render() {
    return (
		<StyledContainer>
		<Card>
				<h2>Privacy Policy:</h2>
				<br />
				<ul>
				<li>We will never sell your personal data to anyone without your consent</li>
				<li>We will never send ads to your email account without your consent</li>
				<li>You will be able to opt-out of any consents at any time</li>
				</ul>
				<br />
		</Card>
		</StyledContainer>
    );
  }
}

export default PrivacyPolicy;
