import React, { useState } from "react";
import "./Login.css";
import { Link, useHistory } from "react-router-dom";
import { auth } from "./firebase";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
	const [isError, setIsError] = useState(false);

	const logout = (event) => {
    event.preventDefault(); // this stops the refresh!!!

		auth
			.signOut()
			.then((auth) => {
			  // Sign-out successful, redirect to homepage
        history.push("/home");
			})
			.catch((e) => {
			  // An error happened.
				alert(e.message)
			});
	};

  const login = (event) => {
    event.preventDefault(); // this stops the refresh!!!

    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        // logged in, redirect to homepage...
        history.push("/home");
      })
      .catch((e) => alert(e.message));
  };

  const register = (event) => {
    event.preventDefault(); // this stops the refresh!!!

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        // created a user and logged in, redirect to homepage...
        history.push("/home");
      })
      .catch((e) => alert(e.message));
  };

  return (

    <Card>
      <Logo src="/lock-img.png" />
			<h1>Sign In</h1>
      <Form>
        <Input
          type="username"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          placeholder="email"
					className="form-control"
        />
        <Input
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          placeholder="password"
					className="form-control"
        />
        <Button onClick={login}>Sign In</Button>
      </Form>
      <Link to="/signup">Don't have an account?</Link>
			<br />
      <Link to="/forgot">Forgot Password?</Link>
			<br />
        { isError &&<Error>The username or password provided were incorrect!</Error> }
    </Card>

  );
}

export default Login;
