import React from 'react';
import axios from 'axios';
//import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import { Card, Logo } from "./AuthForm";
  
class CreatePost extends React.Component {
    constructor() {
    super();
    this.state = {
      input: {},
      errors: {}
    };
     
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
     
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  
    this.setState({
      input
    });
  }
     
  handleSubmit(event) {
    event.preventDefault();
  
    if(this.validate()){
  
        const post = this.state.input;
  
        axios.post(`http://localhost:8080/LiftAndShift/post`, { post })
        .then(res => {
          console.log('res');
          console.log(res);
          console.log(res.data);
  
          let input = {};
          input["title"] = "";
          input["body"] = "";
          this.setState({input:input});
  
          alert('Thanks your Post created successfully.');
  
        })
   
    }
  }
  
  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
  
      if (!input["title"]) {
        isValid = false;
        errors["title"] = "Please enter your title.";
      }
  
      if (!input["body"]) {
        isValid = false;
        errors["body"] = "Please enter your body.";
      }
  
      this.setState({
        errors: errors
      });
  
      return isValid;
  }
     
  render() {
    return (
			<Card >
      <Logo src="/balloon-img.png" />
			<h1>Send Us A Post</h1>
			<br />
      <div>
        <form onSubmit={this.handleSubmit}>
  
          <div className="form-group">
            <input 
              type="text" 
              name="title" 
              value={this.state.input.title}
              onChange={this.handleChange}
              className="form-control" 
              placeholder="title" 
              id="title" />
  
              <div className="text-danger">{this.state.errors.title}</div>
          </div>
					<br />
  
          <div className="form-group">
            <input
              name="body"
              value={this.state.input.body} 
              onChange={this.handleChange}
              placeholder="body"
              className="form-control" />
  
              <div className="text-danger">{this.state.errors.body}</div>
          </div>
					<br />
              
          <input type="submit" value="Submit" className="btn btn-success" />
					<br />
        </form>
				<br />
      </div>
			</Card>
    );
  }
}
  
export default CreatePost;
