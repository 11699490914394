import React, { Component } from 'react';
import About from './About'
//import Header from './Header'

class AboutUs extends Component {
  render() {
    return (
			<>
				<About />
			</>
    );
  }
}

export default AboutUs;
