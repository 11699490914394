import React from 'react'
import "./Home.css";
import "./Switches.css";
import Product from "./Product"

function Switches() {
    return (
        <div className="home">
        <div className="home__row">
             <Product 
                id="GMP-GLC_14-104_1/4"
								partType="Switch"
                title="GLC 14-104 1/4"
                price={9.95}
                image="/GLC_14-104_1:4.png"
								desc="16 Amps"
            />
             <Product 
                id="GMP-GLC_14-104_3/16"
								partType="Switch"
                title="GLC 14-104 3/16"
                price={9.95}
                image="/GLC_14-104_3:16.png"
								desc="16 Amps"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_14-22A"
								partType="Switch"
                title="GLC 14-22A"
                price={10.95}
                image="/GLC_14-22A.png"
								desc="22 Amps"
            />
             <Product 
                id="GMP-22A316ZE"
								partType="Switch"
                title="22A316ZE"
                price={10.95}
                image="/22A316ZE.png"
								desc="22 Amps"
            />
        </div>
    </div>
       
    )
}

export default Switches
