import React from 'react'
import "./Subtotal.css"
import CurrencyFormat from "react-currency-format"
import { useStateValue } from './Stateprovider'
import { getBasketTotal } from './reducer';
import { Link } from 'react-router-dom';
import globalUserStateObject from './globalUserStateObject';


function Subtotal() {
    const [{ basket }, dispatch] = useStateValue();
		const geoState = globalUserStateObject.geoState;

		function getTax(basketTotal) {
			let tax = 0;
			if(geoState === "TX" || geoState === "Texas") {
				tax = basketTotal*0.0815;
			}
			return tax;
		}
		
    return (
        <div className="subtotal">
				<p>Total of all items in your cart:</p>
            <CurrencyFormat 
	            renderText={(value) =>(
	                <>
											<p> ({basket.length} items) </p>
	                    <p>
	                        Subtotal : <strong>{value}</strong>
	                    </p>
	                </>
	            )}
	            decimalScale={2}
	            fixedDecimalScale={2}
	            value={getBasketTotal(basket)}
	            displayType={'text'}
	            thousandSeparator={true}
	            prefix={"$"}
            />
						{geoState === "Texas" || geoState === "TX" ? (
	            <CurrencyFormat 
		            renderText={(value) =>(
		                <>
		                    <p>
		                        Tax : <strong>{value}</strong>
		                    </p>
		                </>
		            )}
		            decimalScale={2}
	            	fixedDecimalScale={2}
		            //value={getBasketTotal(basket)*0.0815}
		            value={getTax(getBasketTotal(basket))}
		            displayType={'text'}
		            thousandSeparator={true}
		            prefix={"$"}
	            />
					) :
					(
            <CurrencyFormat 
	            renderText={(value) =>(
	                <>
	                    <p>
	                        Tax : <strong>{value}</strong>
	                    </p>
	                </>
	            )}
	            decimalScale={2}
	            fixedDecimalScale={2}
	            value={0}
	            displayType={'text'}
	            thousandSeparator={true}
	            prefix={"$"}
            />
					)
				}
            <CurrencyFormat 
	            renderText={(value) =>(
	                <>
	                    <p>
	                        Order Total : <strong>{value}</strong>
	                    </p>
	                </>
	            )}
	            decimalScale={2}
	            fixedDecimalScale={2}
	            value={getBasketTotal(basket)+getTax(getBasketTotal(basket))}
	            displayType={'text'}
	            thousandSeparator={true}
	            prefix={"$"}
            />
        </div>
    )
}

export default Subtotal
