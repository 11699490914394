import firebase from "firebase";


const firebaseConfig = {

    apiKey: "AIzaSyDxTm2kKFZ7F9BUZikZMLxo-RxFD6mgIV4",
    authDomain: "pinkbrainparts.firebaseapp.com",
    projectId: "pinkbrainparts",
    storageBucket: "pinkbrainparts.appspot.com",
    messagingSenderId: "149276076458",
    appId: "1:149276076458:web:661c11eb0f35806031a3d3",
    measurementId: "G-J3LK7Z8ESV"

};

const firebaseApp = firebase.initializeApp(firebaseConfig);




const auth = firebase.auth();

export {auth};

