import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from 'axios';
//import logoImg from "../img/Globe.png";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import { useAuth } from "./auth";

function CreditCard() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");
  const [cvsCode, setCvsCode] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const history = useHistory();

	//function handleCreditCard() {
  const handleCreditCard = (event) => {
		console.log("trying to handle card submission");
		return history.push("/order-confirmation");
	}

	function nextPage() {
		return <Redirect to="/orders" />
	}

  function postCreditCard() {
    axios.post("https://www.somePlace.com/addresses", {
      nameOnCard,
			cardNumber,
			cardExpiration,
			cvsCode,
			postalCode,
    }).then(result => {
      if (result.status === 200) {
				console.log("CreditCard was added");
      } else {
        setIsError(true);
      }
    }).catch(e => {
      setIsError(true);
    });
  }

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Card title="Credit Card Info:">
      <Logo src="/cc-img.png" />
      <Form title="Credit Card Info:">
        <Input
          type="string"
          value={nameOnCard}
          onChange={e => {
            setNameOnCard(e.target.value);
          }}
          placeholder="Name on Card"
        />
        <Input
          type="string"
          value={cardNumber}
          onChange={e => {
            setCardNumber(e.target.value);
          }}
          placeholder="card number"
        />
        <Input
          type="string"
          value={cardExpiration}
          onChange={e => {
            setCardExpiration(e.target.value);
          }}
          placeholder="expiration date, i.e.: mm/dd/yyyy"
        />
        <Input
          type="string"
          value={cvsCode}
          onChange={e => {
            setCvsCode(e.target.value);
          }}
          placeholder="cvs code"
        />
        <Input
          type="string"
          value={postalCode}
          onChange={e => {
            setPostalCode(e.target.value);
          }}
          placeholder="postal code"
        />
        <Button onClick={handleCreditCard}>Save CreditCard</Button>
      </Form>
        { isError &&<Error>The CreditCard provided is not valid!</Error> }
    </Card>
  );
}

export default CreditCard;
