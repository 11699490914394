import React from 'react'
import "./Home.css";
import "./Fuses.css";
import Product from "./Product"

function Fuses() {
    return (
        <div className="home">
        <div className="home__row">
             <Product 
                id="GMP-GLC_HD30"
								partType="FuseHolder"
                title="GLC HD30"
                price={4.90}
                image="/GLC_HD30.png"
								desc="250 VAC"
            />
             <Product 
                id="GMP-GLC_FNMH"
								partType="FuseHolder"
                title="GLC FNMH"
                price={11.95}
                image="/GLC_FNMH.JPG"
								desc="250 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_FNM-20"
								partType="Fuse"
                title="GLC FNM-20"
                price={4.25}
                image="/GLC_FNM-20.png"
								desc="250 VAC"
            />
             <Product 
                id="GMP-GLC_FNM-25"
								partType="Fuse"
                title="GLC FNM-25"
                price={4.25}
                image="/GLC_FNM-25.png"
								desc="250 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_FNM-30"
								partType="Fuse"
                title="GLC FNM-30"
                price={4.25}
                image="/GLC_FNM-30.png"
								desc="250 VAC"
            />
             <Product 
                id="GMP-GLC_4-10"
								partType="Fuse"
                title="GLC 4-10"
                price={2.50}
                image="/GLC_4-20.png"
								desc="250 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_4-12"
								partType="Fuse"
                title="GLC 4-12"
                price={2.50}
                image="/GLC_4-20.png"
								desc="250 VAC"
            />
             <Product 
                id="GMP-GLC_4-15"
								partType="Fuse"
                title="GLC 4-15"
                price={2.50}
                image="/GLC_4-20.png"
								desc="250 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_4-20"
								partType="Fuse"
                title="GLC 4-20"
                price={2.50}
                image="/GLC_4-20.png"
								desc="250 VAC"
            />
             <Product 
                id="GMP-GLC_4-25"
								partType="Fuse"
                title="GLC 4-25"
                price={2.50}
                image="/GLC_4-20.png"
								desc="250 VAC"
            />
        </div>
    </div>
       
    )
}

export default Fuses
