import React, { Component } from 'react';
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import PinDropIcon from '@material-ui/icons/PinDrop';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DirectionsIcon from '@material-ui/icons/Directions';

class About extends Component {
  render() {
    return (
			<Card raised="true">
      <div className="About">
			<h1>About Us</h1>
			<h2>Our Address:</h2>
			<PinDropIcon />
			<ul>
				<li>Global Microwave Parts, LLC</li>
				<li>9894 Monroe Dr</li>
				<li>Dallas, TX 75220</li>
			</ul>
			<br />
			<h2>Hours of Operation:</h2>
			<AvTimerIcon />
			<ul>
				<li>Monday-Friday</li>
				<li>9:00 a.m. to 4:00 p.m.</li>
				<li>Central Standard Time</li>
			</ul>
			<br />
			<h2>Our Location:</h2>
			<DirectionsIcon />
			<ul>
				<li><a href="https://goo.gl/maps/n5u86e6YLU3AhkgZ9" target="_blank" rel="noopener noreferrer">Directions </a></li>
			</ul>
			<br />
      </div>
			</Card>
    );
  }
}

export default About;
