import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
//import logoImg from "../img/Globe.png";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
//import { useAuth } from "../context/auth";

function Profile() {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [isError, setIsError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [addrLine1, setAddrLine1] = useState("");
  const [addrLine2, setAddrLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  function postProfile() {
    axios.post("http://localhost:8080/addresses", {
      firstName,
      lastName,
      companyName,
      emailAddress,
			userPhone,
			addrLine1,
			addrLine2,
			city,
			state,
			postalCode,
    }).then(result => {
      if (result.status === 200) {
				console.log("Profile data was added");
      } else {
        setIsError(true);
      }
    }).catch(e => {
      setIsError(true);
    });
  }

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Card title="User Profile Info:">
      <Logo src="/prof-img.png" />
      <Form>
        <Input
          type="string"
          value={firstName}
          onChange={e => {
            setFirstName(e.target.value);
          }}
          placeholder="first name"
        />
        <Input
          type="string"
          value={lastName}
          onChange={e => {
            setLastName(e.target.value);
          }}
          placeholder="last name"
        />
        <Input
          type="string"
          value={companyName}
          onChange={e => {
            setCompanyName(e.target.value);
          }}
          placeholder="your company name, blank if individual"
        />
        <Input
          type="string"
          value={emailAddress}
          onChange={e => {
            setEmailAddress(e.target.value);
          }}
          placeholder="your email address"
        />
        <Input
          type="string"
          value={userPhone}
          onChange={e => {
            setUserPhone(e.target.value);
          }}
          placeholder="phone"
        />
        <Input
          type="string"
          value={addrLine1}
          onChange={e => {
            setAddrLine1(e.target.value);
          }}
          placeholder="addrLine1"
        />
        <Input
          type="string"
          value={addrLine2}
          onChange={e => {
            setAddrLine2(e.target.value);
          }}
          placeholder="addrLine2"
        />
        <Input
          type="string"
          value={city}
          onChange={e => {
            setCity(e.target.value);
          }}
          placeholder="city"
        />
        <Input
          type="string"
          value={state}
          onChange={e => {
            setState(e.target.value);
          }}
          placeholder="state"
        />
        <Input
          type="string"
          value={postalCode}
          onChange={e => {
            setPostalCode(e.target.value);
          }}
          placeholder="postal code"
        />
        <Button onClick={postProfile}>Save Profile</Button>
      </Form>
        { isError &&<Error>The Profile provided is not valid!</Error> }
    </Card>
  );
}

export default Profile;
