/*
 * this works pretty well 2021-02-20 R2M
 * but I want to add in conditional display of an alert
*/
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class HelloForm extends React.Component {
	constructor(props) {
		super(props)
		console.log("ctor")
		console.log(props)
		this.state = {
			fname: this.props.fname,
			lname: this.props.lname,
			username: this.props.fname + this.props.lname,
    	formIsValid: 'false',
		};
	}

	componentDidLoad() {
		console.log("did load")
	}

	componentWillUnload() {
		console.log("will unload")
	}

	componetDidUpdate() {
		console.log("updating")
	}

	static getDerivedStateFromProps() {
		console.log("derived state")
	}

	formSubmitHandler = (event) => {
    event.preventDefault();
		this.setState({username: this.state.fname + " " + this.state.lname});
		//alert("thanks for submitting");
    this.setState({formIsValid: 'true'});
  }

	formChangeHandler = (event) => {
		let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val});
	}

  render() {
	console.log("rendering....")
   return (
      <div className="myDiv">
        <h1>Hello Mr. {this.state.lname}, {this.state.fname} : username {this.state.username}</h1>
				<br />
				<br />
					<form 
						onSubmit={this.formSubmitHandler} 
					>
						<p>Enter your first name:</p>
					  <input type="text" name="fname" onChange={this.formChangeHandler} /><br />
						<p>Enter your last name:</p>
					  <input type="text" name="lname" onChange={this.formChangeHandler} /><br />
						<br />
						<input
        			type='submit'
      			/>
					</form>
					<br />
					<Alert severity="info">Woot Woot Your Submit was successful!</Alert>
					<br />
      </div>
   ); 
  }
}

export default HelloForm;

//ReactDOM.render(<HelloForm name="RoyC" />, document.getElementById('root'));
