import React from 'react'
import "./Subtotal.css"
import CurrencyFormat from "react-currency-format"
import { useStateValue } from './Stateprovider'
import { getBasketTotal } from './reducer';
import { Link } from 'react-router-dom';
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";

function Subtotal() {
    const [{ basket }, dispatch] = useStateValue();

    return (
        <div className="subtotal-checkout">
            {/* price */}
            <CurrencyFormat 
            renderText={(value) =>(
                <>
                    <p>
                        Subtotal ({basket.length} items) : <strong>{value}</strong>
                    </p>
                </>
            )}
            decimalScale={2}
            fixedDecimalScale={2}
            value={getBasketTotal(basket)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={"$"}
            />
						<br />
						<Link to="/ship-to-address">
            	<Button>Proceed to Checkout</Button>
						</Link>
						<br />
        </div>
    )
}

export default Subtotal
