import React, { Component } from 'react';

class AboutUs extends Component {
  render() {
    return (
      <div className="About">
        <h1>Global Microwave Parts</h1>
				<h2>Our People:</h2>
				<ul>
				<li>Roy Cook</li>
				<li>Linda Cook</li>
				<li>Lauren Cook</li>
				<li>Nichlas Cook</li>
				</ul>
      </div>
    );
  }
}

export default AboutUs;
