import React, { useState } from "react";
import "./Login.css";
//import { auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";
import { Card, Logo, Form, Input, InputArea, Button, Error } from "./AuthForm";
import MailOutlineIcon from '@material-ui/icons/MailOutline';

function ContactUs() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
	const [isError, setIsError] = useState(false);

  const sendEmail = (event) => {
		console.log("send an email please")
	};


  return (

    <Card>
			<h1>Contact Us:</h1>
			<MailOutlineIcon />
      <Form>
        <Input
          type="username"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          placeholder="email"
        />
        <InputArea
          type="textbox"
          value={msg}
          onChange={e => {
            setMsg(e.target.value);
          }}
          placeholder="your message"
        />
        <Button onClick={sendEmail}>Contact Us</Button>
      </Form>
        { isError &&<Error>The email provided was incorrect!</Error> }
    </Card>

  );
}

export default ContactUs;
