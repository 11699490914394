import React, { Component } from 'react';
import SiteMap from './SiteMap'

class Site extends Component {
  render() {
    return (
			<>
				<SiteMap />
			</>
    );
  }
}

export default Site;
