import React from 'react'
import "./Home.css";
import "./Magnetrons.css";
import Product from "./Product"

function Magnetrons() {
	var CurrencyFormat = require('react-currency-format');
    return (
        <div className="home">
        <div className="home__row">
             <Product 
                id="GMP-15928"
								partType="Magnetron"
                title="15928"
                price={69.90}
                image="/15928.JPG"
								desc="1030 Watts"
            />
             <Product 
                id="GMP-2M210"
								partType="Magnetron"
                title="2M210-M1"
                price={69.90}
                image="/2M210.JPG"
								desc="900 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M229E-EH"
								partType="Magnetron"
                title="2M229E-EH"
                price={69.90}
                image="/2M229E-EH.JPG"
								desc="850 Watts"
            />
             <Product 
                id="GMP-2M229E"
								partType="Magnetron"
                title="2M229E"
                price={69.90}
                image="/2M229E.JPG"
								desc="850 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M229H3H"
								partType="Magnetron"
                title="2M229H3H"
                price={69.90}
                image="/2M229H3H.JPG"
								desc="850 Watts"
            />
             <Product 
                id="GMP-2M229H"
								partType="Magnetron"
                title="2M229H"
                price={69.90}
                image="/2M229H.JPG"
								desc="850 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M240H"
								partType="Magnetron"
                title="2M240H"
                price={69.90}
                image="/2M240H.JPG"
								desc="850 Watts"
            />
             <Product 
                id="GMP-2M240H-NS"
								partType="Magnetron"
                title="2M240H-NS"
                price={69.90}
                image="/2M240H-NS.JPG"
								desc="850 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M244"
								partType="Magnetron"
                title="2M244-M1"
                price={69.90}
                image="/2M244.JPG"
								desc="1010 Watts"
            />
             <Product 
                id="GMP-2M248E"
								partType="Magnetron"
                title="2M248E"
                price={69.90}
                image="/2M248E.JPG"
								desc="1030 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M248H-EH"
								partType="Magnetron"
                title="2M248H-EH"
                price={69.90}
                image="/2M248H-EH.JPG"
								desc="1030 Watts"
            />
             <Product 
                id="GMP-2M248H"
								partType="Magnetron"
                title="2M248H"
                price={69.90}
                image="/2M248H.JPG"
								desc="1030 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M248H-NS"
								partType="Magnetron"
                title="2M248H-NS"
                price={69.90}
                image="/2M248H-NS.JPG"
								desc="1030 Watts"
            />
             <Product 
                id="GMP-2M248J"
								partType="Magnetron"
                title="2M248J"
                price={69.90}
                image="/2M248J.JPG"
								desc="1030 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M248K"
								partType="Magnetron"
                title="2M248K"
                price={69.90}
                image="/2M248K.JPG"
								desc="1030 Watts"
            />
             <Product 
                id="GMP-2M248K-NS"
								partType="Magnetron"
                title="2M248K-NS"
                price={69.90}
                image="/2M248K-NS.JPG"
								desc="1030 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M254H"
								partType="Magnetron"
                title="2M254H"
                price={69.90}
                image="/2M254H.JPG"
								desc="850 Watts"
            />
             <Product 
                id="GMP-2M254J"
								partType="Magnetron"
                title="2M254J"
                price={69.90}
                image="/2M254J.JPG"
								desc="850 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-2M303H"
								partType="Magnetron"
                title="2M303H"
                price={69.90}
                image="/2M303H.JPG"
								desc="1080 Watts"
            />
             <Product 
                id="GMP-4150019905"
								partType="Magnetron"
                title="4150019905"
                price={69.90}
                image="/4150019905.JPG"
								desc="1030 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-4358059"
								partType="Magnetron"
                title="4358059"
                price={69.90}
                image="/4358059.JPG"
								desc="850 Watts"
            />
             <Product 
                id="GMP-57-030"
								partType="Magnetron"
                title="57-030"
                price={69.90}
                image="/57-030.JPG"
								desc="860 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-WB27X10249"
								partType="Magnetron"
                title="WB27X10249"
                price={69.90}
                image="/WB27X10249.JPG"
								desc="860 Watts"
            />
             <Product 
                id="GMP-WB27X10249-EH"
								partType="Magnetron"
                title="WB27X10249-EH"
                price={69.90}
                image="/WB27X10249-EH.JPG"
								desc="860 Watts"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-WB27X10249-NS-BH"
								partType="Magnetron"
                title="WB27X10249-NS-BH"
                price={69.90}
                image="/WB27X10249-NS-BH.JPG"
								desc="860 Watts"
            />
             <Product 
                id="GMP-WB27X10505"
								partType="Magnetron"
                title="WB27X10505"
                price={69.90}
                image="/WB27X10505.JPG"
								desc="850 Watts"
            />
        </div>
    </div>
       
    )
}

export default Magnetrons
