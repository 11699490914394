import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth } from "./firebase";
//import logoImg from '/Globe-med.png';
import { Card, Logo, Form, Input, Button } from './AuthForm';

function SignUp() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
	const [isError, setIsError] = useState(false);

  const login = (event) => {
    event.preventDefault(); // this stops the refresh!!!
    // do the login logic...

    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        // logged in, redirect to homepage...
        history.push("/home");
      })
      .catch((e) => alert(e.message));
  };

  const register = (event) => {
    event.preventDefault(); // this stops the refresh!!!
    // do the register logic...

    auth
      .createUserWithEmailAndPassword(email.toString().trim(), password.trim())
      .then((auth) => {
        // created a user and logged in, redirect to homepage...
        history.push("/home");
      })
      .catch((e) => alert(e.message));
  };

  return (
    <Card>
      <Logo src="/mwo-img.png" />
      <Form>
        <Input 
					type="email" 
					value={email}
					placeholder="email" 
					onChange={e => {
            setEmail(e.target.value);
          }}
				/>
        <Input 
					type="password" 
					value={password}
					placeholder="password"
					onChange={e => {
            setPassword(e.target.value);
          }}
				/>
        <Input type="password" placeholder="password again" />
        <Button onClick={register}>Sign Up</Button>
      </Form>
      <Link to="/signin">Already have an account?</Link>
			<br />
     </Card>
  );
}

export default SignUp;
