import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from 'axios';
//import logoImg from "../img/Globe.png";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import { useAuth } from "./auth";

function Address() {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [addrLine1, setAddrLine1] = useState("");
  const [addrLine2, setAddrLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const history = useHistory();

  function postAddress() {
    axios.post("http://localhost:8080/addresses", {
      userName,
			userPhone,
			addrLine1,
			addrLine2,
			city,
			state,
			postalCode,
    }).then(result => {
      if (result.status === 200) {
				console.log("ShipTo Address was added");
      } else {
        setIsError(true);
      }
    }).catch(e => {
      setIsError(true);
    });
  }

	const validate = {
	  //firstName: name => nameValidation('First Name', name),
	  //lastName: name => nameValidation('Last Name', name),
	  //email => emailValidation('Email', email)
	};

	const emailValidation = email => {
	  if (
	    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
	      email,
	    )
	  ) {
	    return null;
	  }
	  if (email.trim() === '') {
	    return 'Email is required';
	  }
	  return 'Please enter a valid email';
	};
	
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Card title="Ship To Info:">
      <Logo src="/addr-img.png" />
      <Form validate={validate} >
        <Input
          type="username"
          value={userName}
          onChange={e => {
            setUserName(e.target.value);
          }}
          placeholder="email"
        />
        <Input
          type="string"
          value={userPhone}
          onChange={e => {
            setUserPhone(e.target.value);
          }}
          placeholder="phone"
        />
        <Input
          type="string"
          value={addrLine1}
          onChange={e => {
            setAddrLine1(e.target.value);
          }}
          placeholder="addrLine1"
        />
        <Input
          type="string"
          value={addrLine2}
          onChange={e => {
            setAddrLine2(e.target.value);
          }}
          placeholder="addrLine2"
        />
        <Input
          type="string"
          value={city}
          onChange={e => {
            setCity(e.target.value);
          }}
          placeholder="city"
        />
        <Input
          type="string"
          value={state}
          onChange={e => {
            setState(e.target.value);
          }}
          placeholder="state"
        />
        <Input
          type="string"
          value={postalCode}
          onChange={e => {
            setPostalCode(e.target.value);
          }}
          placeholder="postal code"
        />
        <Button onClick={thanks}>Save Address</Button>
      </Form>
        { isError &&<Error>The Address provided is not valid!</Error> }
    </Card>
  );

	function thanks() {
		return history.push("/order-confirmation");
	}
}

export default Address;
