import React, { Component } from 'react';
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";

class SiteMap extends Component {
  render() {
    return (
			<Card raised="true">
      <div className="About">
			<h1>Site Map</h1>
			<h2>Parts</h2>
				Magnetrons
				<br />Switches
				<br />Diodes
				<br />TStats
			<br />
			<h2>Checkout</h2>
				Checkout
				<br />CheckoutProduct
				<br />Order
				<br />OrderConfirmation
			<br />
			<h2>Administrative</h2>
				AboutUs
				<br />PrivacyPolicy
				<br />Copyright
				<br />ContactUs
				<br />SiteMap
			<br />
			<br />
      </div>
			</Card>
    );
  }
}

export default SiteMap;
