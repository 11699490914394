import React from 'react';
import { Link } from 'react-router-dom';
import "./Footer.css";

function Footer() {
    return (
			<>
      <div className="footer">
				<table>
					<tr>
						<td><Link to="/home" style={{ color: '#FFF' }}>&nbsp;Home</Link></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td><Link to="/privacy-policy" style={{ color: '#FFF' }}>Privacy Policy</Link></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td><Link to="/copyright" style={{ color: '#FFF' }}>Copyright</Link></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td><Link to="/about-us" style={{ color: '#FFF' }}>About Us</Link></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td><Link to="/contact-us" style={{ color: '#FFF' }}>Contact Us</Link></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td><Link to="/site-map" style={{ color: '#FFF' }}>Site Map</Link></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
				</table>
      </div>  
			</>
    );
}

export default Footer

