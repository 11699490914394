import React, { useState } from 'react';
import ReactGlobe from 'react-globe';
 
// import optional tippy styles for tooltip support
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
 
export default function ExampleRotatingGlobe () {
  // support rendering markers with simple data
  const markers = [
    {
      id: 'marker1',
      city: 'Dallas',
      color: 'red',
      coordinates: [33.3521, 97.8198],
      value: 50,
    },
  ];
 
  // simple and extensive options to configure globe
  const options = {
    ambientLightColor: 'red',
    cameraRotateSpeed: 0.5,
    focusAnimationDuration: 2000,
    focusEasingFunction: ['Linear', 'None'],
    pointLightColor: 'gold',
    pointLightIntensity: 1.5,
    globeGlowColor: 'blue',
    markerTooltipRenderer: marker => `${marker.city} (${marker.value})`,
  };
 
  const [globe, setGlobe] = useState(null);
  console.log(globe); // captured globe instance with API methods
 
  // simple component usage
  return (
<>
    <ReactGlobe
      height="100vh"
      globeBackgroundTexture="https://your/own/background.jpg"
      globeCloudsTexture={null}
      globeTexture="https://your/own/globe.jpg"
      initialCoordinates={[1.3521, 103.8198]}
      markers={markers}
      options={options}
      width="100%"
      onClickMarker={(marker, markerObject, event) => console.log(marker, markerObject, event)}
      onGetGlobe={setGlobe}
		 / >
</>
  )
}
