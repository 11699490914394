import React from 'react';
import axios from 'axios';
import { useStateValue } from "./Stateprovider";
import { Link, Redirect, useHistory } from "react-router-dom";
import "./Order.css"
import "./OrderProduct.css"
//import "./OrderConfirmationProduct.css"
import OrderProduct from './OrderProduct';
import OrderSubtotal from './OrderSubtotal';
import Subtotal from "./Subtotal";
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";
import globalUserStateObject from './globalUserStateObject'
import CurrencyFormat from 'react-currency-format';

//const winston = require('winston');
//const logger  = new winston.transports.Console;
//const files = new winston.transports.File({ filename: './combined.log' });
//winston.add(logger);


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

async function postOrderItem(item) {
	console.log("Posting an item in the order");
	console.log(item);
  await axios.post(`http://cyberpojos.com:8080/LiftAndShift/post`, { item })
  .then(res => {
		console.log("post of Order was successful");
	});
}

function Order() {
    const [{ basket }] = useStateValue();
    //const geoState = useStateValue();
		const orderNo = getRandomInt(5000);
  	const history = useHistory();
		const geoState = globalUserStateObject.geoState;

		console.log("Order UP");
		console.log(basket);
		//winston.log(basket);
		console.log("Order UP geoState");
		console.log(geoState);

    return (
        <div className="checkout">

            {basket?.length === 0 ? (
                <div>
                    <h2> Your Order is Empty</h2>
                    <p>You have no items in youir basket. </p>
										<p>To buy one or more items return to Home and start over.</p>
										<br />
										<br />
                </div>
            ) : 
            	(
								<>
								<div className="basket_info">
                  <h2 className="checkout__title">Please review your Order below:
	              	<b><OrderSubtotal /></b>
									<br />
									<br />
									<br />
									<br />
									</h2>
	            		<h1>Your Order Number { orderNo } is now on file pending your payment.</h1>
	            		<h1>To make Payment please call 1-800-325-8488</h1>
									<br />
									<br />
									<table>
                  {basket.map(item => (
											console.log({orderNo},{item}),
											postOrderItem({orderNo}),
											postOrderItem( {item} ),
											<tr>
												<td>&nbsp;</td>
												<td>&nbsp;{item.partType}</td>
												<td>&nbsp;</td>
												<td>&nbsp;{item.title}</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
						            <CurrencyFormat 
							            renderText={(value) =>(
							               <p>
							                  <strong>{value}</strong>
							               </p>
							            )}
							            decimalScale={2}
							            fixedDecimalScale={true}
							            value={item.price}
							            displayType={'text'}
							            thousandSeparator={true}
							            prefix={" $ "}
												/>
												<td>&nbsp;</td>
											</tr>
                  ))
									}
									</table>
									<br />
                </div>
								</>
            )}
        </div>
    );
}

export default Order
