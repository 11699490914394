import React from 'react'
import "./Home.css";
import "./Diodes.css";
import Product from "./Product"

function Diodes() {
    return (
        <div className="home">
        <div className="home__row">
             <Product 
                id="GMP-GLC_0526"
								partType="Diode"
                title="GLC 0526"
                price={10.25}
                image="/GLC_0526.JPG"
								desc="12 KV, 550 ma"
            />
             <Product 
                id="GMP-GLC_0529"
								partType="Diode"
                title="GLC 0529"
                price={10.25}
                image="/GLC_0529.JPG"
								desc="12 KV, 550 ma"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_0530"
								partType="Diode"
                title="GLC 0530"
                price={11.25}
                image="/GLC_0530.JPG"
								desc="12 KV, 550 ma"
            />
             <Product 
                id="GMP-GLC_3-A"
								partType="Diode"
                title="GLC 3-A"
                price={10.25}
                image="/GLC_3-A.JPG"
								desc="12 KV, 550 ma"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-GLC_3-M"
								partType="Diode"
                title="GLC 3-M"
                price={10.25}
                image="/GLC_3-M.JPG"
								desc="12 KV, 550 ma"
            />
             <Product 
                id="GMP-WB27X1160"
								partType="Diode"
                title="WB27X1160"
                price={11.25}
                image="/WB27X1160.JPG"
								desc="12 KV, 550 ma"
            />
        </div>
    </div>
       
    )
}

export default Diodes
