import React, { Component } from 'react';
import { Card, Logo, Form, Input, Button, Error } from "./AuthForm";

class SiteHealth extends Component {
  render() {
    return (
      <div className="siteHealth">
      	<Logo src="/ambu-img.png" />
				<h2>Site Health:</h2>
				<ul>
				<li>Front-End-Status: OK</li>
				<li> Back-End-Status: OK</li>
				<li></li>
				</ul>
			</div>
    );
  }
}

export default SiteHealth;
