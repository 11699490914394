		import React, { useState } from "react";
		import { Link, useHistory } from "react-router-dom";
		import { auth } from "./firebase";
		//import logoImg from '/Globe-med.png';
		import { Card, Logo, Form, Input, Button } from './AuthForm';
		
		function ForgotPassword() {
		  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
	const [isError, setIsError] = useState(false);

  const reset = (event) => {
    event.preventDefault(); // this stops the refresh!!!
    auth
			.sendPasswordResetEmail(email);
      history.push("/signin");
  };

  return (
    <Card>
      <Logo src="/lock-img.png" />
      <Form>
        <Input 
					type="email" 
					value={email}
					placeholder="email" 
					onChange={e => {
            setEmail(e.target.value);
          }}
				/>
        <Button onClick={reset}>Send Password Reset Email</Button>
      </Form>
     </Card>
  );
}

export default ForgotPassword;
