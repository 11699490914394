import React from 'react'
import "./Home.css";
import "./Capacitors.css";
import Product from "./Product"

function Capacitors() {
    return (
        <div className="home">
        <div className="home__row">
             <Product 
                id="GMP-0.55_MFD"
								partType="Capacitor"
                title="0.55 MFD"
                price={22.90}
                image="/DSCN0723.JPG"
								desc="2500 VAC"
            />
             <Product 
                id="GMP-0.63_MFD"
								partType="Capacitor"
                title="0.63 MFD"
                price={22.90}
                image="/DSCN0724.JPG"
								desc="2100 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-0.69_MFD"
								partType="Capacitor"
                title="0.69 MFD"
                price={22.90}
                image="/DSCN0725.JPG"
								desc="2500 VAC"
            />
             <Product 
                id="GMP-0.75_MFD"
								partType="Capacitor"
                title="0.75 MFD"
                price={22.90}
                image="/DSCN0726.JPG"
								desc="2500 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-0.80_MFD"
								partType="Capacitor"
                title="0.80 MFD"
                price={22.90}
                image="/DSCN0727.JPG"
								desc="2500 VAC"
            />
             <Product 
                id="GMP-0.86_MFD"
								partType="Capacitor"
                title="0.86 MFD"
                price={22.90}
                image="/DSCN0728.JPG"
								desc="2100 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-0.90_MFD"
								partType="Capacitor"
                title="0.90 MFD"
                price={22.90}
                image="/DSCN0729.JPG"
								desc="2100 VAC"
            />
             <Product 
                id="GMP-0.95_MFD"
								partType="Capacitor"
                title="0.95 MFD"
                price={22.90}
                image="/DSCN0730.JPG"
								desc="2100 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-0.90_MFD"
								partType="Capacitor"
                title="0.90 MFD"
                price={22.90}
                image="/DSCN0731.JPG"
								desc="2100 VAC"
            />
             <Product 
                id="GMP-0.96_MFD"
								partType="Capacitor"
                title="0.96 MFD"
                price={22.90}
                image="/DSCN0732.JPG"
								desc="2500 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-1.00_MFD"
								partType="Capacitor"
                title="1.00 MFD"
                price={22.90}
                image="/DSCN0733.JPG"
								desc="2300 VAC"
            />
             <Product 
                id="GMP-1.00-187"
								partType="Capacitor"
                title="1.00-187"
                price={22.90}
                image="/DSCN0734.JPG"
								desc="2100 VAC"
            />
        </div>
        <div className="home__row">
             <Product 
                id="GMP-1.05_MFD"
								partType="Capacitor"
                title="1.05 MFD"
                price={22.90}
                image="/DSCN0735.JPG"
								desc="2100 VAC"
            />
        </div>
    </div>
       
    )
}

export default Capacitors
