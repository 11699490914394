import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { Card, Logo } from "./AuthForm";

class Orders extends Component {
  render() {
    return (
			<Card raised="true">
      <Logo src="/site-map-img.png" />
      <div className="Orders">
				<h2>Orders:</h2>
				<ul>
				<li><a href="http://cyberpojos.com:8041/gmp/" target="_blank">Orders</a></li>
				</ul>
				<br />
			</div>
			</Card>
    );
  }
}

export default Orders;
